import { io } from "socket.io-client";
import {SESSION_STORAGE_SESSION_ID} from "../../constants";

let socket = null;

const WebsocketService = {
  async init(userId) {
    socket = await io.connect(process.env.VUE_APP_WEBSOCKET_URL, {
      transports: ["websocket"],
      query: {
        room: `carteiro-via-varejo:${userId}`,
      },
    });

    return socket.on('connect', () => {
      sessionStorage.setItem(SESSION_STORAGE_SESSION_ID, socket.id);
    })
  },

  socket() {
    return socket;
  },

  close: function () {
    if (socket) {
      socket.disconnect();
      socket.on("disconnect", () => {
        sessionStorage.setItem(SESSION_STORAGE_SESSION_ID, null)
      });

      socket = null;
    }
  },
};

export default WebsocketService;
