import Vue from 'vue'
import localforage from 'localforage'
import router from '../../router'

export default (http) => {
    http.interceptors.response.use(response => response, error => {
        const { response } = error

        let message = 'Ops. Algo de errado aconteceu...'

        function notify() {
            Vue.$n(response.data.message ? response.data.message : message, 'error')
        }

        if (response.status == 400) {
            notify()
        }

        if (response.status == 401) {
            localforage.removeItem('token')

            router.push({
                name: 'home'
            })

            notify()
        }

        if (response.status == 404) {
            notify()
        }

        return Promise.reject(response)
    })
}
