import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import WebsocketService from "@/support/services/websocket";

import {
  ROUTE_HOME_NAME,
  ROUTE_DASHBOARD_NAME,
  ROUTE_BLOCKED_PAGE_NAME,
} from '@/support/constants'
import {SESSION_STORAGE_SESSION_ID} from "../support/constants";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: ROUTE_HOME_NAME,
    component: () => import('../views/pages/home/index'),
    meta: {
      visitor: true
    }
  },
  {
    path: '/dashboard',
    name: ROUTE_DASHBOARD_NAME,
    component: () => import('../views/pages/dashboard/index'),
    meta: {
      auth: true
    }
  },
  {
    path: '/blocked-page',
    name: ROUTE_BLOCKED_PAGE_NAME,
    component: () => import('../views/pages/blocked_page/index'),
    meta: {
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const handleQuerySettings = q => {
  if (q.access_token) {
    localforage.setItem('token', {
      'access_token': q.access_token,
      'token_type': 'Bearer'
    })
  }
}

router.beforeEach(
  (to, from, next) => {
    if (!_.isEmpty(to.query)) {
      handleQuerySettings(to.query)
    }

    store.dispatch('auth/setToken').then(() => {
      if (to.meta.visitor) {
        next({ name: ROUTE_DASHBOARD_NAME })
        return
      }
      next()
    }).catch(() => {
      if (to.meta.auth) {
        next({ name: ROUTE_HOME_NAME })
        return
      }
      next()
    })

    if (WebsocketService.socket())
      WebsocketService.socket().on("session-tabs", (sessions) => {
        const currentSessionId = sessionStorage.getItem(SESSION_STORAGE_SESSION_ID)
        const found = sessions.find(item => item.active)

        if(to.name !== ROUTE_BLOCKED_PAGE_NAME) {
          if(found && found.socket_id !== currentSessionId) {
            next({ name: ROUTE_BLOCKED_PAGE_NAME })
            return
          }
        }else{
          if(found && found.socket_id !== currentSessionId) {
            next({ name: ROUTE_BLOCKED_PAGE_NAME })
            return
          }
        }

        next({ name: ROUTE_HOME_NAME })
      });
  }
)

export default router
