import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

function notification(message, type = 'success', duration = 5000) {
    Vue.notify({
        group: 'panel',
        type,
        duration,
        text: message
    })
}

export default function install(Vue) {
    Object.defineProperty(Vue, '$n', {
        get() {
            return notification;
        }
    })

    Object.defineProperty(Vue.prototype, '$n', {
        get() {
            return notification;
        }
    })
}