import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import http from './support/http'
import bus from './support/bus'
import localforage from 'localforage'
import notifications from './support/notifications'
import UUID from 'vue-uuid'

// Define tip config
Vue.config.productionTip = false

// Register local store with namespace
localforage.config({
  driver: localforage.LOCALSTORAGE,
  storeName: 'viavarejo-carteiro-digital'
})

// Config router with store
sync(store, router)

// Register UUID class
Vue.use(UUID)

// Event bus global
Vue.use(bus)

// Register moment component
Vue.use(require('vue-moment'))

// HTTP request config
Vue.use(http, { store, router })

// Initialize notification managers
Vue.use(notifications)

import whiteLabelPlugin from '@/support/plugins/whiteLabel'
Vue.use(whiteLabelPlugin)

// If there is a token in local storage, a request will request the user's data. If it fails 
// the token will be removed from the local storage
setTimeout(() => {
  store.dispatch('auth/setToken')
    .then(() => store.dispatch('auth/fetchData').catch(() => store.dispatch('auth/clearAuth')))
    .catch(() => store.dispatch('auth/clearAuth'))
    .finally(() => {
      // Initialize application
      new Vue({
        router,
        store,
        render: function (h) { return h(App) }
      }).$mount('#app')
    })

}, 200)