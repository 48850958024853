const whiteLabel = require('../../../whitelabel.config')[process.env.VUE_APP_BRAND]

const whiteLabelPlugin = {
    install(Vue) {
        Vue.prototype.$whiteLabel = {
            id: process.env.VUE_APP_BRAND,
            brand: whiteLabel.brand,
        }
    }
}

export default whiteLabelPlugin