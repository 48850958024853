const ROUTE_HOME_NAME = 'home'
const ROUTE_DASHBOARD_NAME = 'dashboard'
const ROUTE_BLOCKED_PAGE_NAME = 'blocked-page'

const SESSION_STORAGE_SESSION_ID = 'via-varejo/session_id'

export {
    ROUTE_HOME_NAME,
    ROUTE_DASHBOARD_NAME,
    ROUTE_BLOCKED_PAGE_NAME,

    SESSION_STORAGE_SESSION_ID
}
