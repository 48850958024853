<template>
  <div id="app">
    <notifications
        classes="notification"
        group="panel"
        position="bottom right"
    />
    <transition mode="out-in" name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Application",
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>

<style lang="scss">
@import "~whiteLabel/scss/variables";

@import "./assets/scss/fonts";
@import "./assets/scss/reset";
@import "./assets/scss/foundation";
@import "./assets/scss/transitions";
@import "./assets/scss/classes";
@import "./assets/scss/notifications";

@include foundation-xy-grid-classes();
@include foundation-flex-classes();

body {
  color: $black;
  font-family: $roboto;
  background: #f0f2f5;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $borderColor;
    border: 1px solid rgba($gray, 0.5);
    border-radius: 5px;
    transition: 0.5s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $borderColor;
  }

  &::-webkit-scrollbar-thumb:active {
    background: $borderColor;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-left: 1px solid $borderColor;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }

  &::-webkit-scrollbar-track:active {
    background: #ffffff;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
</style>