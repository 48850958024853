import axios from 'axios';
import interceptors from './interceptors'

export const http = axios.create({
    baseURL: process.env.VUE_APP_API_ROBBU,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const setHttpToken = (token) => {
    if (_.isEmpty(token)) {
        http.defaults.headers.common['Authorization'] = '';
    } else {
        let accessToken = `Bearer ${token.access_token}`;
        http.defaults.headers.common['Authorization'] = accessToken;
    }
}

export default function install(Vue, { store, router }) {
    interceptors(http, store, router)
    Object.defineProperty(Vue.prototype, '$http', {
        get() {
            return http;
        }
    });
}