const whitelabelConfig = {
    'robbu': {
        'brand': 'Robbu',
        'assetsPath': 'src/assets/white-label/robbu',
    },
    'via-varejo': {
        'brand': 'Via Varejo',
        'assetsPath': 'src/assets/white-label/via-varejo',
    }
}

module.exports = whitelabelConfig