import { http } from "../support/http";

const authApi = () => {
    return {
        accounts: {
            login: (data) => {
                http.defaults.baseURL = 'https://api-accounts.robbu.global/v1'
                return http.post('login', data)
            },
            user: () => {
                http.defaults.baseURL = 'https://api-accounts.robbu.global/v1'
                return http.get('user')
            }
        },
        login: (data) => {
            http.defaults.baseURL = `${process.env.VUE_APP_API_ROBBU}/v1`
            return http.post('login', data)
        },
        logout: () => {
            http.defaults.baseURL = process.env.VUE_APP_API_INVENIOLIVE
            return http.post('logout')
        },
        validate: (companyId) => {
            http.defaults.baseURL = 'https://robbu-custom-viavarejo.azurewebsites.net/v1/postman/viavarejo/validate'
            return http.post(`${companyId}`)
        },
        user: () => {
            http.defaults.baseURL = process.env.VUE_APP_API_INVENIOLIVE
            return http.get('user')
        }
    }
}

export default authApi
