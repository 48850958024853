import Vue from 'vue'
import {setHttpToken} from '@/support/http'
import localforage from 'localforage'
import router from '@/router'
import authApi from '@/api/auth'
import {isEmpty} from 'lodash'

import {
    ROUTE_HOME_NAME,
    ROUTE_DASHBOARD_NAME
} from '@/support/constants'

import WebSocketService from '@/support/services/websocket'
import auth from "../../api/auth";

const state = {
    user: {}
}

const getters = {
    user(state) {
        return state.user
    }
}

const actions = {
    async fetchData({dispatch, commit}) {
        return authApi().user()
            .then(({data}) => {
                commit('SET_USER_DATA', data)

                WebSocketService.init(data.id)

                return data
            })
    },

    async authenticateUser({commit, dispatch}, data) {
        if (this._vm.$whiteLabel.id === 'via-varejo') {
            return await authApi().login(data)
                .then(({data}) => {
                    dispatch('setToken', data).then(() => {
                        dispatch('fetchData').then((user) => {
                            authApi().validate(user.company_id)
                                .then(() => {
                                    // Apenas os usuários com os seguintes tipo de pefil podem
                                    // acessar o sistema: Super-administrador, Supervisor e Carteiro Digital
                                    if ([1, 3, 4, 7].includes(user.role)) {
                                        router.push({
                                            name: ROUTE_DASHBOARD_NAME
                                        })
                                    } else {
                                        Vue.$n('Apenas os usuários com os seguintes tipo de pefil podem acessar o sistema: Supervisor e Carteiro Digital.', 'error', 20000)
                                        dispatch('clearAuth').then(() => {
                                            router.push({
                                                name: ROUTE_HOME_NAME
                                            })
                                        })
                                    }
                                })
                                .catch(() => {
                                    Vue.$n('A sua crendencial não tem autorização para acessar a plataforma.', 'error', 20000)
                                    dispatch('clearAuth').then(() => {
                                        router.push({
                                            name: ROUTE_HOME_NAME
                                        })
                                    })
                                })
                        })
                    })
                }).catch(() => {
                    Vue.$n('Algum erro ocorreu na tentativa de acessar sua conta.', 'error')
                })
        } else {
            return await authApi().accounts.login(data)
                .then(({data}) => {
                    dispatch('setToken', data).then(() => {
                        dispatch('fetchData')
                            .then((user) => {
                                if ([1, 3, 4, 7].includes(user.role)) {
                                    router.push({
                                        name: ROUTE_DASHBOARD_NAME
                                    })
                                } else {
                                    Vue.$n('Apenas os usuários com os seguintes tipo de pefil podem acessar o sistema: Supervisor e Carteiro Digital.', 'error', 20000)
                                    dispatch('clearAuth').then(() => {
                                        router.push({
                                            name: ROUTE_HOME_NAME
                                        })
                                    })
                                }
                            })
                    })
                })
                .catch(() => Vue.$n('Algum erro ocorreu na tentativa de acessar sua conta.', 'error'))
        }
    },
    setToken({dispatch}, token) {
        if (isEmpty(token)) {
            return dispatch('checkTokenExists').then((token) => {
                setHttpToken(token)
            })
        }

        dispatch('setTokenLocalStorage', token)
        setHttpToken(token)
        return token
    },
    setTokenLocalStorage({commit}, token) {
        if (isEmpty(token)) {
            localforage.removeItem('token', token)
            return
        }
        localforage.setItem('token', token)
    },
    checkTokenExists() {
        return localforage.getItem('token').then((token) => {
            if (isEmpty(token)) {
                return Promise.reject('NO_STORAGE_TOKEN')
            }

            return Promise.resolve(token)
        })
    },
    clearAuth({commit, dispatch}) {
        Promise.all([
            authApi().logout(),
            dispatch('setTokenLocalStorage', null)
        ]).finally(() => {
            setHttpToken(null)
            commit('SET_USER_DATA', null)
            WebSocketService.close()
            router.push({
                name: ROUTE_HOME_NAME
            }).catch(() => {
            })
        })
    }
}

const mutations = {
    SET_USER_DATA(state, user) {
        state.user = user
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
